import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./config/aws-amplify";

function renderApp() {
  ReactDOM.render(<App />, document.getElementById("root"));
}

renderApp();

if (module.hot) {
  module.hot.accept(["./App"], () => {
    renderApp();
  });
}
