import { types } from "mobx-state-tree";
import Device from "./Device";

export default types.model("File", {
  id: types.identifierNumber,
  filename: types.string,
  serial: types.string, // node Serial
  size: types.maybeNull(types.number),
  devices: types.maybeNull(types.array(Device)),
  nodeName: types.maybeNull(types.string),
  dateTo: types.maybeNull(types.string),
  dateFrom: types.maybeNull(types.string),
  dateToInternational: types.maybeNull(types.string)
});
