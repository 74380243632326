import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Files from "../containers/Files";
import Logout from '../containers/Logout';

export default () => {
  return (
    <>
      <Switch>
        <Route key="files" exact path="/" component={Files} />
        <Route key="logout" exact path="/logout" component={Logout} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};
