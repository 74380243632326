import { types, flow } from "mobx-state-tree";
import { Auth as AwsAuth } from "aws-amplify";
import { extendStateModel } from "./innerTypes";

let cognitoUser = null;

export const AuthStore = types
  .model(
    "Authentication",
    extendStateModel({
      token: types.maybeNull(types.string),
      //user: types.maybe(types.frozen())
      user: types.maybeNull(types.boolean),
      organizations: types.array(types.string)
    })
  )
  .actions(self => ({
    afterCreate: flow(function* rehydrate() {
      try {
        const user = yield AwsAuth.currentAuthenticatedUser();
        console.log({ user });
        const session = yield AwsAuth.currentSession();
        console.log({ session });
        self.getOrganizationList(session.idToken);
        self.token = session.idToken.jwtToken;
      } catch (e) {
        console.log({ e });
        self.token = null;
      }
    }),
    login: flow(function* login(email) {
      self.loading = true;
      try {
        cognitoUser = yield AwsAuth.signIn(email);
        self.user = true;
      } catch (e) {
        console.log({ error: e });
      }
      self.loading = false;
    }),
    logout: flow(function* logoutProcess() {
      yield AwsAuth.signOut();
      self.token = null;
    }),
    getToken: flow(function* getToken(loginCode) {
      self.loading = true;
      yield AwsAuth.sendCustomChallengeAnswer(cognitoUser, loginCode);

      // Amplify or Cognito will return expired or invalid token
      // but it loads just fine on page refresh
      window.location.reload();
    }),
    getOrganizationList: token => {
      const { payload } = token;
      if (payload) {
        const field = "custom:organizations";
        self.organizations = payload[field].split(";");
      } else {
        console.error("Payload is not present in the token");
      }
    }
  }));

export const authStore = AuthStore.create({
  token: null,
  user: false,
  organizations: []
});
