import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import {
  BasicInput,
  FullHeightContainer,
  Label,
  Separator,
  Well
} from "../components/Common";
import {
  FormContainer,
  FormCopy,
  FormLogo,
  FormTitle,
  SubmitButton
} from "../components/LoginForm/styles";
import { emailRegexp } from "../utils/regexp";

const checkEmail = str => !str.match(emailRegexp);

const Login = props => {
  const { auth } = props;
  const { loading, login, user } = auth;

  const [email, setEmail] = useState("");
  const [emailError, setError] = useState(false);

  if (user) {
    return <Redirect to="/code" />;
  }
  return (
    <FullHeightContainer py={5}>
      <Well center>
        <FormContainer>
          <FormLogo />
          <Separator />
          <FormTitle>Welcome!</FormTitle>
          <FormCopy>Please, use your credentials to enter</FormCopy>
          <Label>Email</Label>
          <BasicInput
            placeholder={"Enter your email here"}
            name="email"
            value={email}
            onChange={event => {
              const { value } = event.target;
              const check = checkEmail(value);
              setEmail(value);
              setError(check);
            }}
          />
          {emailError && <p>Invalid email</p>}

          <SubmitButton
            processing={loading}
            disabled={loading || emailError}
            onClick={() => {
              login(email);
            }}
            label={"Submit"}
          />
        </FormContainer>
      </Well>
    </FullHeightContainer>
  );
};

export default inject("auth")(observer(Login));
