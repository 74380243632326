import { types, flow, applySnapshot } from "mobx-state-tree";
import { observable } from "mobx";
import { save } from "save-file";
import { extendStateModel } from "../innerTypes";
import File from "./File";
import { find, extractDate, keys } from "../../utils/utils";
import { DOWNLOADING, DONE } from "../../components/FileList/DownloadButton";

const FILE_SERVER = process.env.REACT_APP_FILE_SERVER;

export const FileListStore = types
  .model(
    "File Store",
    extendStateModel({
      id: types.identifier,
      name: types.string,
      loading: types.boolean,
      list: types.maybeNull(types.array(File)),
      latestMonth: types.maybeNull(types.string),
      latestYear: types.maybeNull(types.string)
    })
  )
  .volatile(self => ({
    // let's try not to put anything here for now
    downloadStatus: observable({})
  }))
  .actions(self => ({
    download: flow(function* getZipFile({ token, file }) {
      const { filename, serial, id } = file;
      self.downloadStatus[id] = DOWNLOADING;

      const clearName = filename.slice(0, -4);
      const filePath = `${self.id}/${serial}/${clearName}.zip`;
      const url = `${FILE_SERVER}/${filePath}`;

      const response = yield fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 400) {
        const zipUrl = response.url;
        const zipResponse = yield fetch(zipUrl);
        const fileName = `${clearName}.zip`;

        const blob = yield zipResponse.blob();
        self.downloadStatus[id] = DONE;
        yield save(blob, fileName);
      }
    }),
    fetchMetaFile: flow(function* getMetaFile(token) {
      self.loading = true;
      // TODO: provide token here to url, so we could get proper url from lambda
      const url = `${FILE_SERVER}/${self.id}/meta.json`;

      const response = yield fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 400) {
        const metaUrl = response.url;
        const metaResponse = yield fetch(metaUrl);
        const meta = yield metaResponse.json();

        self.indexedData = {};
        self.indexedMonths = {};

        self.name = meta.organization.name;

        const locale = navigator.language || "en-US";
        const { format } = new Intl.DateTimeFormat(locale);
        const mappedMeta = meta.files.map((file, index) => {
          const { id, filename, serial, size, devices } = file;
          const nodeName = file["node_name"];
          const dateFrom = file["date_from"] || "unset";
          const dateTo = file["date_to"] || "unset";
          const dateToInternational =
            dateTo === "unset" ? "unset" : format(new Date(dateTo));

          if (dateTo !== "unset") {
            const { year, month } = extractDate(dateTo);

            const hash = `${year}${month}`;
            const hashAll = `${year}all`;

            if (!self.indexedData[hashAll]) {
              self.indexedData[hashAll] = [index];
            } else {
              self.indexedData[hashAll].push(index);
            }

            if (!self.indexedData[hash]) {
              self.indexedData[hash] = [index];
            } else {
              self.indexedData[hash].push(index);
            }

            if (!self.indexedMonths[year]) {
              self.indexedMonths[year] = {
                list: ["all", month],
                [month]: 1,
                total: 1
              };
            } else {
              if (self.indexedMonths[year].list.indexOf(month) < 0) {
                self.indexedMonths[year].list.push(month);
                self.indexedMonths[year][month] = 1;
              } else {
                self.indexedMonths[year][month] += 1;
              }
              self.indexedMonths[year].total += 1;
            }
          }

          const mappedFile = {
            id,
            filename,
            serial,
            size,
            devices,
            nodeName,
            dateTo,
            dateFrom,
            dateToInternational
          };
          return File.create(mappedFile);
        });


        applySnapshot(self.list, mappedMeta);
        const yearsList = keys(self.indexedMonths);
        const latestYear = yearsList[yearsList.length - 1];
        const monthsList = self.indexedMonths[latestYear].list;
        const latestMonth = monthsList[monthsList.length - 1];
        self.latestMonth = latestMonth.toString();
        self.latestYear = latestYear.toString();
        self.loading = false;
      }
    })
  }))
  .views(self => ({
    get filteredList() {
      const { searchQuery, queryParams, withDevices } = self;
      const findQuery = find(searchQuery);
      return searchQuery.length > 0 || withDevices
        ? self.datedSlice.filter(file => {
            const noDevices = !file.devices || file.devices.length === 0;
            if (withDevices && noDevices) {
              return false;
            } else {
              if (queryParams.length === 0) {
                return (
                  findQuery(file.filename) || findQuery(file.id, searchQuery)
                  // TODO: shall we filter through devices list as well
                );
              }
              return queryParams.reduce((acc, param) => {
                const { key, value } = param;
                const findValue = find(value);
                let temp = false;
                switch (key) {
                  case "id":
                    const idFound = findValue(file.id);
                    temp = idFound;
                    break;
                  case "serial":
                    temp = findValue(file.serial);
                    break;
                  case "filename":
                    temp = findValue(file.filename);
                    break;
                  case "device":
                    if (noDevices) {
                      temp = false;
                    } else {
                      temp = file.devices.reduce((acc, device) => {
                        return (
                          acc ||
                          (findValue(device.name) ||
                            (device.model && findValue(device.model)) ||
                            (device.serial && findValue(device.serial)))
                        );
                      }, false);
                    }
                    break;
                  default:
                    temp = false;
                }
                return acc || temp;
              }, false);
            }
          })
        : self.datedSlice;
    },
    get currentPageList() {
      const { filteredList, currentPage, itemsPerPage } = self;
      const cursor = currentPage * itemsPerPage;
      return filteredList.slice(cursor, cursor + itemsPerPage);
    },
    get pagesTotal() {
      return Math.ceil(self.filteredList.length / self.itemsPerPage) - 1;
    }
  }));
