import React from "react";
import { InputContainer, MainInput, SearchIcon } from "./styles";

const SearchInput = props => {
  const { value, onChange, placeholder, ...rest } = props;
  return (
    <InputContainer {...rest}>
      <SearchIcon />
      <MainInput placeholder={placeholder} value={value} onChange={onChange} />
    </InputContainer>
  );
};

export default SearchInput;
