import { types } from "mobx-state-tree";
import { RouterModel } from "mst-react-router";
import { authStore, AuthStore } from "./Auth";
import { routingStore } from "./Routing";
import { filter, Filter } from "./Filter";

// Devtools
import makeInspectable from "mobx-devtools-mst";

const Model = types.model("Legacy Environet", {
  auth: AuthStore,
  history: RouterModel,
  filter: Filter
});

export const store = Model.create({
  auth: authStore,
  history: routingStore,
  filter
});

makeInspectable(store);
