import React from "react";
import styled, { css } from "styled-components";
import { Flex } from "../Common";
import { rotate } from "../Common/animations";

export const IDLE = "IDLE";
export const DOWNLOADING = "DOWNLOADING";
export const DONE = "DONE";

const Container = styled(Flex)`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  cursor: ${({ status }) =>
    !status || status === "IDLE" ? "pointer" : "normal"};
  transition: background-color 0.5s ease-in-out;
  background-color: ${({ status, theme }) => {
    const { green, inactiveGrey, blue } = theme.colors;
    switch (status) {
      case IDLE:
        return green;
      case DOWNLOADING:
        return inactiveGrey;
      case DONE:
        return blue;
      default:
        return green;
    }
  }};
`;

const IconContainer = styled.svg`
  width: 100%;
  height: 100%;
  ${({ status }) => {
    switch (status) {
      case IDLE:
        return css`
          animation: none;
        `;
      case DOWNLOADING:
        return css`
          animation: ${rotate} 1s linear infinite;
        `;
      case DONE:
        return css`
          animation: none;
        `;
      default:
        return 0;
    }
  }};
`;

export const CustomIcon = ({ status }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {status === DONE && (
        <path
          d="M20.8308 34.6072L12.3807 25.8907C11.8731 25.367 11.8731 24.518 12.3807 23.9942L14.2192 22.0978C14.7268 21.574 15.55 21.574 16.0577 22.0978L21.75 27.9696L33.9423 15.3928C34.45 14.8691 35.2732 14.8691 35.7808 15.3928L37.6193 17.2892C38.1269 17.8129 38.1269 18.662 37.6193 19.1857L22.6692 34.6073C22.1615 35.1309 21.3384 35.1309 20.8308 34.6072Z"
          fill="#E6F7FC"
        />
      )}

      {status === DOWNLOADING && (
        <path
          d="M39.0929 16.8634L37.139 17.9916C36.6333 18.2835 36.4322 18.9152 36.6828 19.4425C38.4983 23.2625 38.3398 27.7817 36.207 31.4681C34.079 35.1615 30.2443 37.5586 26.0285 37.8963C25.4465 37.9429 25 38.433 25 39.0168V41.2731C25 41.9277 25.5566 42.4409 26.2097 42.3953C31.9609 41.9933 37.2074 38.7459 40.1042 33.7182C43.0082 28.6989 43.1972 22.5317 40.6696 17.35C40.3826 16.7615 39.6599 16.5361 39.0929 16.8634Z"
          fill="#FBFCFF"
        />
      )}

      {(status === IDLE || !status) && (
        <path
          d="M26.9375 19.6406V13H17.1719C16.5225 13 16 13.5225 16 14.1719V36.8281C16 37.4775 16.5225 38 17.1719 38H33.5781C34.2275 38 34.75 37.4775 34.75 36.8281V20.8125H28.1094C27.4648 20.8125 26.9375 20.2852 26.9375 19.6406ZM30.6704 29.9609L25.9624 34.6338C25.6377 34.9565 25.1133 34.9565 24.7886 34.6338L20.0806 29.9609C19.585 29.4692 19.9326 28.625 20.6299 28.625H23.8125V24.7188C23.8125 24.2871 24.1621 23.9375 24.5938 23.9375H26.1562C26.5879 23.9375 26.9375 24.2871 26.9375 24.7188V28.625H30.1201C30.8174 28.625 31.165 29.4692 30.6704 29.9609ZM34.4082 18.127L29.6279 13.3418C29.4082 13.1221 29.1104 13 28.7979 13H28.5V19.25H34.75V18.9521C34.75 18.6445 34.6279 18.3467 34.4082 18.127Z"
          fill="#F3FFF7"
        />
      )}
    </svg>
  );
};

const DownloadButton = ({ title, status, action = () => {} }) => {
  return (
    <Container
      title={title}
      status={status}
      onClick={!status ? action : () => {}}
    >
      <IconContainer status={status}>
        <CustomIcon status={status} />
      </IconContainer>
    </Container>
  );
};

export default DownloadButton;
