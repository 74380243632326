export const { keys } = Object;

export const find = query => (value, noSpaces = true) => {
  const sValue = typeof value === "string" ? value : `${value}`;
  const lcValue = sValue.toLowerCase();
  const lcQuery = query.toLowerCase();
  const cleanQuery = noSpaces ? lcQuery.replace(/\s+/, "") : lcQuery;
  return lcValue.includes(cleanQuery);
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const extractDate = dateString => {
  const [year, month] = dateString.split("-");
  return { year, month: parseInt(month) };
};

export const getMonth = number => {
  const index = parseInt(number) - 1;
  return months[index];
};

export const validate = (check, onError) => str => {
  const error = check(str);
  if (error) {
    onError(error);
  }
};

export const sortByKey = (array, key, reverse) => {
  const reverseMultiplier = reverse ? -1 : 1;
  const copyArray = [...array];
  copyArray.sort(function(a, b) {
    let x, y;
    if (typeof key === "function") {
      x = key(a);
      y = key(b);
    } else {
      x = a[key];
      y = b[key];
    }
    return (x < y ? -1 : x > y ? 1 : 0) * reverseMultiplier;
  });
  return copyArray;
};
