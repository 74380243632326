import React from "react";
import styled from "styled-components";
import { Box, Text, Button, Logo, Loading } from "../Common";

export const FormContainer = styled(Box).attrs({
  width: "450px",
  px: 5,
  py: 6,
  alignItems: "center"
})``;

export const FormLogo = styled(Logo).attrs({
  height: "50px",
  mb: 6
})``;

export const FormTitle = styled(Text).attrs({
  as: "h1",
  color: "darkBlue",
  fontSize: 6,
  fontWeight: 700
})``;

FormTitle.defaultProps = {
  mb: 2
};

export const FormCopy = styled(Text).attrs({
  fontSize: 2,
  mb: 6
})``;

FormCopy.defaultProps = {
  mb: 6
};

export const Submit = styled(Button).attrs({
  bg: "green",
  border: "2px solid",
  borderColor: "darkGreen",
  width: "100%",
  color: "white",
  py: 3,
  borderRadius: 2,
  fontSize: 4,
  fontWeight: 700,
  height: "72px"
})``;

export const SubmitButton = props => {
  const { processing, label, ...rest } = props;
  const buttonLabel = processing ? (
    <>
      <Loading width="30px" />
      <Text>Processing</Text>
    </>
  ) : (
    label
  );

  return (
    <Submit disabled={processing} {...rest}>
      {buttonLabel}
    </Submit>
  );
};

SubmitButton.defaultProps = {
  processing: false,
  label: "Submit"
};
