import styled from "styled-components";
import { grid } from "styled-system";
import { Grid, Text, Flex, Column, Row } from "../Common";
import deviceImage from "../../assets/icons/device.svg";
import { IDLE, DONE, DOWNLOADING } from "./DownloadButton";

const gridTemplate = `180px 4fr  1fr 200px 60px`;
const midGrid = `repeat(3, 1fr)`;

export const Container = styled(Grid).attrs({
  bg: "white",
  p: 4,
  mb: 3,
  borderRadius: 2
})`
  height: 108px;
  grid-template-columns: ${gridTemplate};
  grid-template-areas: "serial description date size download";
  box-shadow: 0 3px 5px rgba(200, 211, 226, 0.5), 0 0 1px rgba(113, 147, 195, 1);
`;

export const Copy = styled(Text).attrs({
  fontSize: 3,
  color: "copy",
  mb: 1
})`
  display: flex;
`;

export const DeviceInfo = styled(Column).attrs({
  justifyContent: "center"
})``;

export const Info = styled(Text).attrs({
  marginRight: 2
})``;

export const InfoLabel = styled(Text).attrs({
  fontSize: 1
})`
  &:after {
    content: ":";
  }
`;

export const DateValue = styled(Text).attrs({
  fontSize: 3
})`
  font-weight: 900;
`;

export const DeviceIcon = styled(Flex).attrs({
  mr: 2
})`
  background: url(${deviceImage});
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  background-repeat: no-repeat;
`;

export const DeviceName = styled(Text).attrs(({ active }) => ({
  fontSize: 1,
  fontWeight: 700,
  color: active ? "purple" : "inactiveGrey"
}))``;

export const DeviceSerial = styled(Text).attrs(({ active }) => ({
  fontSize: 0,
  color: active ? "purple" : "inactiveGrey"
}))``;

export const SerialPart = styled(Column).attrs({
})`
  grid-area: serial;
  justify-content: center;
`;

export const DevicesPart = styled(Row).attrs({})`
  grid-area: devices;
  grid-template-columns: ${midGrid};
`;

export const NodeName = styled(Text).attrs(({ noMargin }) => ({
  mb: noMargin ? 0 : 2
}))`
  & b {
    margin-left: 5px;
  }
`;

export const NodeSerial = styled(Text).attrs({})`
  & b {
    margin-left: 5px;
  }
`;

export const DeviceContainer = styled(Flex).attrs({
  mr: 6,
  alignItems: "center"
})``;

export const Description = styled(Column).attrs({
  color: "darkGrey"
})`
  grid-area: description;
  justify-content: center;
`;

export const DatePart = styled(Column).attrs({})`
  grid-area: date;
  align-items: flex-end;
  justify-content: center;
`;

export const FileSizePart = styled(Column).attrs({
  pr: 4
})`
  grid-area: size;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  color: ${({ status, theme }) => {
    const { green, inactiveGrey, blue } = theme.colors;
    switch (status) {
      case IDLE:
        return green;
      case DOWNLOADING:
        return inactiveGrey;
      case DONE:
        return blue;
      default:
        return green;
    }
  }};
`;
export const DownloadPart = styled(Flex).attrs({})`
  grid-area: download;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled(Grid).attrs({
  px: 4,
  py: 2,
  my: 4,
  mx: "auto"
})`
  width: 100%;
  max-width: 1200px;
  align-items: center;
  grid-template-columns: ${gridTemplate};
  grid-template-areas: "serial description date size download";
`;

export const HeaderCell = styled(Row).attrs(({ alignRight }) => ({
  color: "greyBlue",
  fontWeight: 700,
  fontSize: 3,
  justifyContent: alignRight ? "flex-end" : "flex-start"
}))`
  ${grid};
  letter-spacing: 0.5px;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const CenterPart = styled(Grid).attrs({})`
  grid-area: description;
  grid-template-columns: ${midGrid};
`;
