import React from "react";
import { Container, BigLoading, Message } from "./styles";

const Wait = ({ text }) => {
  return (
    <Container>
      <BigLoading />
      <Message>{text}</Message>
    </Container>
  );
};

Wait.defaultProps = {
  text: "Loading, please wait..."
};

export default Wait;
