import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../containers/Login";
import LoginCode from "../containers/LoginCode";

export default () => (
  <>
    <Switch>
      <Route exact path="/" key="login" component={Login} />
      <Route exact path="/code" key="code" component={LoginCode} />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  </>
);
