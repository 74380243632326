import React from "react";
import { ArrowsContainer, ArrowDown, ArrowUp } from "./styles";

const SortArrows = props => {
  const { active, reverse, ...rest } = props;
  if (!active) {
    return null;
  }
  return (
    <ArrowsContainer {...rest}>
      <ArrowDown active={active && !reverse} />
      <ArrowUp active={active && reverse} />
    </ArrowsContainer>
  );
};

export default SortArrows;
