import React, { Component } from "react";
import { Column, FullHeightContainer, Row, Well } from "../components/Common";
import { inject, observer } from "mobx-react";
import { SingleItem } from "../components/FileList";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import Wait from "../components/Wait";

class Files extends Component {
  componentDidMount() {
    const { auth, filter } = this.props;
    const { loadOrgData } = filter;
    const { token, organizations } = auth;
    loadOrgData({ token, orgList: organizations });
  }

  render() {
    const { filter } = this.props;
    const { currentOrg, loading } = filter;
    if (loading) {
      return (
        <FullHeightContainer>
          <Well center>
            <Wait />
          </Well>
        </FullHeightContainer>
      );
    }

    if (!currentOrg) {
      return null;
    }
    const { prevPage, nextPage, setPage, pagesTotal } = filter;
    const { currentPage, currentPageList } = filter;
    const download = currentOrg ? currentOrg.download : () => {};
    const { downloadStatus } = currentOrg;
    const { auth } = this.props;
    const { token } = auth;

    return (
      <>
        <Header />
        <Column mx="auto" mb={5} maxWidth={"1200px"} alignItems="center">
          <Column width="100%">
            {loading ? (
              <Wait />
            ) : (
              currentPageList.map(file => {
                if (!file) return null;
                const { id } = file;
                return (
                  <SingleItem
                    status={downloadStatus[id]}
                    file={file}
                    key={id}
                    download={() => download({ token, file })}
                  />
                );
              })
            )}
          </Column>
          {pagesTotal > 0 && (
            <Row justifyContent="flex-end" ml="auto">
              <Pagination
                pagesTotal={pagesTotal}
                nextPage={nextPage}
                prevPage={prevPage}
                currentPage={currentPage + 1}
                setPage={setPage}
              />
            </Row>
          )}
        </Column>
      </>
    );
  }
}

export default inject("auth", "filter")(observer(Files));
