import React from "react";
import { ChangePage, Page } from "./styles";

const Pagination = props => {
  const { pagesTotal, currentPage, nextPage, prevPage, setPage } = props;
  if (pagesTotal < 2) {
    return null;
  }

  const windowSize = 3;
  const minimumWindow = 7;
  const pages = Array(pagesTotal)
    .fill()
    .map((_, i) => i + 1)
    .filter(item => {
      if (pagesTotal > windowSize){
        if ((pagesTotal - minimumWindow) >= item && currentPage - windowSize > item){
          console.log('case - 1');
          return false
        }

        if (item > minimumWindow && currentPage + windowSize < item){
          console.log('case - 2');
          return false;
        }
      }
      return true
    });
  return (
    <>
      <ChangePage
        mr={1}
        prev
        disabled={currentPage === 1}
        onClick={prevPage}
      />
      {pages.map(num => (
        <Page
          mr={1}
          active={currentPage === num}
          onClick={() => {
            setPage(num);
          }}
        >
          {num}
        </Page>
      ))}
      <ChangePage disabled={currentPage === pagesTotal} onClick={nextPage} />
    </>
  );
};

export default Pagination;
