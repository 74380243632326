import { types } from 'mobx-state-tree';


export const String = types.string;
export const OptionalString = (init = "") => types.optional(String, init);
export const Bool = types.boolean;
export const OptionalBool = (init = false) => types.optional(Bool, init);


export const extendStateModel = (obj) => {
  return {
    ...obj,
    loading: types.optional(types.boolean, false),
    error: types.maybe(types.frozen())
  }
};
