import styled from "styled-components";
import { Column, Loading, Text } from "../Common";

export const Container = styled(Column).attrs({
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  width: "100%",
  height: "100%"
})``;

export const BigLoading = styled(Loading).attrs({
  width: "50px",
  height: "50px",
  mr: 0,
  mb: 4,
  color: "lightBlue"
})``;

export const Message = styled(Text).attrs({
  color: "lightBlue"
})``;
