import React from "react";

import { Logo, Row } from "../Common";
import {
  HeaderMain,
  HeaderContainer,
  LogoutContainer,
  IconLogout,
  LogoutLabel
} from "./styles";
import { inject, observer } from "mobx-react";
import Dropdown from "../Dropdown";
import { Item } from "../Dropdown/styles";
import DatePickerControl from "../FilterBox/DatePicker";
import SearchInput from "../SearchInput";
import {
  HeaderContainer as SortHeaderContainer,
  CenterPart,
  HeaderCell
} from "../FileList/styles";
import SortArrows from "../SortArrows";

const Header = props => {
  const { filter } = props;
  const { setFilter, selectOrg } = filter;
  const { currentOrg, orgs, searchQuery } = filter;
  const { selectedYear, selectedMonth } = filter;
  const { sortBy, sortParam, sortReversed } = filter;

  const { indexedMonths } = currentOrg;
  const orgOptions = orgs.map(org => {
    return {
      id: org.id,
      name: org.name
    };
  });
  return (
    <HeaderMain>
      <HeaderContainer>
        <Logo height="32px" />
        <Row
          flex={1}
          px={6}
          width={"100%"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Dropdown
            mr={3}
            propertyName="id"
            width="300px"
            value={currentOrg.name}
            options={orgOptions}
            renderItem={(org, collapse) => {
              return (
                <Item
                  key={org.id}
                  onClick={() => {
                    collapse();
                    selectOrg(org.id);
                  }}
                >
                  {org.name}
                </Item>
              );
            }}
          />
          <SearchInput
            mr={3}
            value={searchQuery}
            placeholder={"Filter"}
            onChange={event => setFilter({ searchQuery: event.target.value })}
          />
          <DatePickerControl
            data={indexedMonths}
            year={selectedYear}
            month={selectedMonth}
            changeYear={value => setFilter({ selectedYear: value })}
            changeMonth={value => {
              setFilter({ selectedMonth: "" + value });
            }}
          />
        </Row>
        <LogoutContainer to="/logout">
          <IconLogout />
          <LogoutLabel>Logout</LogoutLabel>
        </LogoutContainer>
      </HeaderContainer>
      <SortHeaderContainer>
        <HeaderCell onClick={() => sortBy("serial")}>
          Serial
          <SortArrows
            reverse={sortReversed}
            active={sortParam === "serial"}
            ml={3}
          />
        </HeaderCell>
        <CenterPart>
          <HeaderCell onClick={() => sortBy("name")}>
            Name
            <SortArrows
              reverse={sortReversed}
              active={sortParam === "name"}
              ml={3}
            />
          </HeaderCell>
        </CenterPart>
        <HeaderCell onClick={() => sortBy("dateTo")} alignRight>
          <SortArrows
            reverse={sortReversed}
            active={sortParam === "dateTo"}
            mr={3}
          />
          Date
        </HeaderCell>
        <HeaderCell pr={4} onClick={() => sortBy("size")} alignRight>
          <SortArrows
            reverse={sortReversed}
            active={sortParam === "size"}
            mr={3}
          />
          File Size
        </HeaderCell>
      </SortHeaderContainer>
    </HeaderMain>
  );
};

export default inject("filter")(observer(Header));
