import { types, flow } from "mobx-state-tree";
import { extendStateModel } from "./innerTypes";
import { FileListStore } from "./FileList";
import { find, sortByKey } from "../utils/utils";

const SingleParam = types.model({
  key: types.string,
  value: types.string
});

export const Filter = types
  .model(
    "Filter",
    extendStateModel({
      currentOrg: types.maybeNull(types.reference(FileListStore)),
      selectedYear: types.maybeNull(types.string),
      selectedMonth: types.maybeNull(types.string),
      searchQuery: types.string,
      queryParams: types.array(SingleParam),
      currentPage: types.number,
      itemsPerPage: types.number,
      sortParam: types.string,
      sortReversed: types.boolean,
      orgs: types.array(FileListStore)
    })
  )
  .actions(self => ({
    onCreate: () =>{
      self.loading = true;
    },
    loadOrgData: flow(function*({ orgList, token }) {
      self.loading = true;
      self.orgs = orgList.map(id => {
        return FileListStore.create({
          id,
          name: "",
          list: [],
          loading: false,
          loadingFiles: [],
          latestMonth: "",
          latestYear: ""
        });
      });
      yield Promise.all(self.orgs.map(org => org.fetchMetaFile(token)));
      const firstId = self.orgs[0].id;
      self.currentOrg = self.orgs[0];
      self.selectOrg(firstId);
      self.loading = false;
    }),
    selectOrg: id => {
      const { orgs } = self;
      const { setFilter } = self;
      const selectedOrg = orgs.find(org => org.id === id);
      self.currentOrg = selectedOrg;

      setFilter({
        selectedYear: selectedOrg.latestYear
      });
    },
    setFilter: newFilter => {
      for (const key in newFilter) {
        if (key !== "searchQuery") {
          if (key === "selectedYear") {
            self.selectedYear = newFilter[key];
            self.selectedMonth = "all";
          } else {
            self[key] = newFilter[key];
          }
        } else {
          self[key] = newFilter[key];
        }
      }
      self.currentPage = 0;
    },
    sortBy: sortParam => {
      if (sortParam === self.sortParam) {
        self.sortReversed = !self.sortReversed;
      } else {
        self.sortReversed = false;
      }
      self.sortParam = sortParam;
    },
    prevPage: () => {
      let newPageIndex = self.currentPage - 1;
      self.currentPage = newPageIndex < 0 ? 0 : newPageIndex;
    },
    nextPage: () => {
      let newPageIndex = self.currentPage + 1;
      self.currentPage =
        newPageIndex >= self.pagesTotal ? self.pagesTotal : newPageIndex;
    },
    setPage: newPageIndex => {
      if (newPageIndex < 0) {
        self.currentPage = 0;
      } else if (newPageIndex > self.pagesTotal) {
        self.currentPage = self.pagesTotal;
      } else {
        self.currentPage = newPageIndex - 1;
      }
    }
  }))
  .views(self => ({
    get datedSlice() {
      const { list, indexedData } = self.currentOrg;
      const { selectedYear, selectedMonth } = self;
      if (!selectedYear || !selectedMonth) {
        return list;
      }
      const hash = `${selectedYear}${selectedMonth}`;
      const hashAll = `${selectedYear}all`;
      // TODO: check that we have data here...
      return selectedMonth === "all"
        ? indexedData[hashAll].map(index => list[index])
        : indexedData[hash].map(index => list[index]);
    },
    get sortedList() {
      const { sortParam, sortReversed, datedSlice } = self;
      let result = null;
      switch (true) {
        case sortParam === "dateTo": {
          result = sortByKey(
            datedSlice,
            item => new Date(item.dateTo).getTime(),
            sortReversed
          );
          break;
        }
        case sortParam === "serial": {
          result = sortByKey(datedSlice, "serial", sortReversed);
          break;
        }
        case sortParam === "name": {
          result = sortByKey(datedSlice, "nodeName", sortReversed);
          break;
        }
        case sortParam === "size": {
          result = sortByKey(datedSlice, "size", sortReversed);
          break;
        }
        default:
          result = datedSlice;
      }

      return result;
    },
    get filteredList() {
      if (!self.currentOrg) {
        return [];
      }
      const { searchQuery } = self;
      const findQuery = find(searchQuery);
      if (searchQuery.length > 0) {
        return self.sortedList.filter(file => {
          const deviceFound = file.devices
            ? file.devices.reduce((acc, device) => {
                const queryDevice =
                  findQuery(device.name) ||
                  findQuery(device.model) ||
                  findQuery(device.serial);
                return acc || queryDevice;
              }, false)
            : false;
          return (
            findQuery(file.filename) ||
            findQuery(file.serial, searchQuery) ||
            deviceFound
          );
        });
      }
      return self.sortedList;
    },
    get currentPageList() {
      const { filteredList, currentPage, itemsPerPage } = self;
      const cursor = currentPage * itemsPerPage;
      return filteredList.slice(cursor, cursor + itemsPerPage);
    },
    get pagesTotal() {
      const { filteredList } = self;
      return Math.ceil(filteredList.length / self.itemsPerPage) - 1;
    }
  }));

export const filter = Filter.create({
  currentOrg: null,
  selectedYear: "",
  selectedMonth: "",
  searchQuery: "",
  currentPage: 0,
  itemsPerPage: 25,
  sortParam: "dateTo",
  sortReversed: false,
  orgNames: []
});
