import styled from "styled-components";
import { Flex, Input, Row } from "../Common";
import search from "../../assets/icons/search.svg";

export const InputContainer = styled(Row).attrs({})`
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const MainInput = styled(Input).attrs({
  px: 3,
  py: "14px",
  pl: 6,
  color: "darkGrey",
  borderRadius: 3,
  fontSize: "20px"
})`
  width: 100%;
  font-weight: 700;
  border: ${({ theme }) => `3px solid ${theme.colors.inputBorderLight}`};
  &:focus {
    border: ${({ theme }) => `3px solid ${theme.colors.inputBorder}`};
  }
`;

export const SearchIcon = styled(Flex).attrs({
  left: 3
})`
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 1;
  background-image: url(${search});
`;
