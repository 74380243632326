import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import {
  BasicInput,
  FullHeightContainer,
  Label,
  Separator,
  Well
} from "../components/Common";
import {
  FormContainer,
  FormCopy,
  FormLogo,
  FormTitle,
  SubmitButton
} from "../components/LoginForm/styles";

const LoginCode = props => {
  const { auth } = props;
  const { loading, user, getToken } = auth;
  const [code, setCode] = useState("");

  if (!user) {
    return <Redirect to="/" />;
  }
  return (
    <FullHeightContainer py={5}>
      <Well center>
        <FormContainer>
          <FormLogo />
          <Separator />
          <FormTitle>Login Code</FormTitle>
          <FormCopy>Check provided email for login code</FormCopy>
          <Label>Login Code</Label>
          <BasicInput
            placeholder={"Enter 6 digit code"}
            value={code}
            onChange={event => {
              setCode(event.target.value);
            }}
          />
          <SubmitButton
            onClick={() => {
              getToken(code);
            }}
            label={"Submit"}
            processing={loading}
          />
        </FormContainer>
      </Well>
    </FullHeightContainer>
  );
};

export default inject("auth")(observer(LoginCode));
