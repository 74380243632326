import styled from "styled-components";
import { Row, Image } from "../Common";
import arrowDown from '../../assets/icons/sort-arrow-down.svg';

export const ArrowsContainer = styled(Row)``;

const Arrow = styled(Image).attrs({
  src: arrowDown
})`
  flex: 0 0 auto;
  cursor: pointer;
  width: 20px;
  height: 12px;
  opacity: ${({active}) => active ? 1 : 0.25};
  &:hover{
    opacity: 1;
  }
`;

export const ArrowUp = styled(Arrow)`
  transform: scaleY(-1);
`;

export const ArrowDown = styled(Arrow).attrs({
  src: arrowDown
})``;
