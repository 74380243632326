import styled, { css } from "styled-components";
import {
  space,
  width,
  height,
  fontSize,
  color,
  border,
  margin,
  flexbox,
  grid,
  borderRadius,
  fontFamily,
  fontWeight,
  background,
  maxWidth,
  textAlign,
  layout,
  position
} from "styled-system";
import { NavLink } from "react-router-dom";

import logo from "../../assets/img/logo.png";
import { ReactComponent as Processing } from "../../assets/icons/processing.svg";
import { rotate } from "./animations";

export const Flex = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${border}
  ${margin}
  ${flexbox}
  ${borderRadius}
  ${background}
  ${maxWidth}
  ${layout}
  ${position}
  box-sizing: border-box;
`;

export const Input = styled(Flex).attrs({
  as: "input"
})`
  outline: none;
  border: none;
`;

export const Text = styled.p`
  margin: 0;  
  ${color}
  ${space}
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${textAlign}
  ${width}
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Grid = styled.div`
  display: grid;
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${border}
  ${margin}
  ${borderRadius}
  ${grid}
  ${textAlign}
`;

export const Icon = styled.i.attrs(({ icon, iconGroup = "" }) => ({
  className: `fa${iconGroup} fa-${icon}`
}))`
  ${fontSize}
  ${space}
  ${layout}
  ${color}
`;

export const Box = styled(Column).attrs({
  bg: "white",
  borderRadius: 3
})`
  box-shadow: 0 3px 10px 1px #dde4f3;
`;

export const Image = styled.img.attrs({})`
  ${width}
  ${height}
  ${space}
`;

export const Logo = styled(Image).attrs({
  src: logo
})`
  width: auto;
`;

export const Separator = styled(Flex).attrs({
  width: "100%",
  height: "1px",
  bg: "inputBorderLight"
})`
  ${height}
`;

Separator.defaultProps = {
  mb: 6
};

export const Label = styled(Text).attrs({
  color: "darkGrey",
  fontSize: 2,
  mb: 2,
  textAlign: "left",
  alignItems: "flex-start",
  width: "100%"
})`
  &:after {
    content: ":";
  }
`;

export const BasicInput = styled(Input).attrs({
  p: 3,
  color: "darkGrey",
  borderRadius: 2,
  fontSize: 3
})`
  width: 100%;
  font-weight: 700;
  border: ${({ theme }) => `2px solid ${theme.colors.inputBorderLight}`};
  &:focus {
    border: ${({ theme }) => `2px solid ${theme.colors.inputBorder}`};
  }
`;

BasicInput.defaultProps = {
  mb: 3
};

export const Button = styled(Flex).attrs({
  as: "button",
  justifyContent: "center",
  alignItems: "center"
})`
  ${fontWeight};
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const EmptyLink = styled(Flex).attrs({
  as: NavLink
})`
  text-decoration: none;
`;

export const Loading = styled(Processing).attrs({
  mr: 3
})`
  ${space};
  fill: ${({ color, theme }) => theme.colors[color] || color};
  animation: ${rotate} 1s linear infinite;
`;

Loading.defaultProps = {
  color: "#fcfcfc"
};

export const FullHeightContainer = styled(Column).attrs({
  minHeight: "100vh"
})``;

export const Well = styled(Column).attrs({
  maxWidth: "1200px",
  height: "100%",
  flex: 1,
  mx: "auto"
})`
  ${({ center }) => {
    return (
      center &&
      css`
        align-items: center;
        justify-content: center;
      `
    );
  }}
`;

Well.defaultProps = {
  center: false
};
