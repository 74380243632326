import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class Logout extends Component {
  componentDidMount() {
    const { auth } = this.props;
    auth.logout();
  }

  render() {
    return <p>Signing out...</p>;
  }
}

export default inject('auth')(observer(Logout));
