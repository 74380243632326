import React from "react";
import {
  Container,
  DeviceInfo,
  DateValue,
  DeviceIcon,
  DeviceName,
  DeviceSerial,
  DevicesPart,
  DatePart,
  NodeName,
  DeviceContainer,
  CenterPart,
  Description,
  SerialPart,
  FileSizePart,
  DownloadPart,
  HeaderContainer,
  HeaderCell
} from "./styles";
import DownloadButton from "./DownloadButton";

export const Device = ({ name, model, serial, index, empty = false }) => {
  const deviceName = name ? `${name}${model ? `-${model}` : ""}` : "No Devices";
  const withSerial = !!serial;
  return (
    <DeviceContainer areaIndex={index}>
      <DeviceIcon empty={!name} />
      <DeviceInfo>
        <DeviceName mb={serial ? 1 : 0} active={!empty}>
          {deviceName}
        </DeviceName>
        {serial && (
          <DeviceSerial active={withSerial}>
            SN:{serial || "Not specified"}
          </DeviceSerial>
        )}
      </DeviceInfo>
    </DeviceContainer>
  );
};

export const SingleItem = ({ file, status, download }) => {
  const { size, serial, nodeName, dateToInternational } = file;
  const { devices } = file;

  const sizeInKilobytes = size / 10 ** 3;
  const sizeInMegaBytes = size / 10 ** 6;
  const sizeDiff = sizeInKilobytes < 1000;
  const label = sizeDiff ? "Kb" : "Mb";
  return (
    <Container>
      <SerialPart>
        <DateValue>{serial}</DateValue>
      </SerialPart>

      {devices ? (
        <Description>
          <NodeName>
            Name:<b>{nodeName}</b>
          </NodeName>
          <DevicesPart>
            {devices.map((device, index) => (
              <Device {...device} index={index} />
            ))}
          </DevicesPart>
        </Description>
      ) : (
        <Description>
          <NodeName noMargin>
            Name:<b>{nodeName}</b>
          </NodeName>
        </Description>
      )}

      <DatePart>
        <DateValue>{dateToInternational}</DateValue>
      </DatePart>

      <FileSizePart>
        <DateValue>{`${(sizeDiff ? sizeInKilobytes : sizeInMegaBytes).toFixed(
          2
        )} ${label}`}</DateValue>
      </FileSizePart>

      <DownloadPart>
        <DownloadButton
          action={download}
          status={status}
          title={!status && "Click do download"}
        />
      </DownloadPart>
    </Container>
  );
};

export const ListHeader = () => {
  return (
    <HeaderContainer>
      <HeaderCell>Serial</HeaderCell>
      <CenterPart>
        <HeaderCell>Name</HeaderCell>
        <HeaderCell>Date</HeaderCell>
      </CenterPart>
      <HeaderCell textAlign="right" pr={4}>
        File Size
      </HeaderCell>
    </HeaderContainer>
  );
};
