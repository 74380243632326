import styled from "styled-components";
import { Column, Flex, Text } from "../Common";
import arrowDown from "../../assets/icons/arrow-down.svg";

export const SelectContainer = styled(Flex).attrs({})`
  position: relative;
  cursor: pointer;
  min-width: 150px;
  flex: 0 0 auto;
`;

export const MainContainer = styled(Flex).attrs({
  borderRadius: 3
})`
  width: 100%;
  overflow: hidden;
  border: ${({ theme }) => `3px solid ${theme.colors.greyBlue}`};
`;

export const CurrentValue = styled(Flex).attrs({
  px: 3,
  fontSize: 3
})`
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  font-weight: bold;
`;

export const ExpandButton = styled(Flex).attrs({
  as: "button",
  bg: "greyBlue"
})`
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  padding: 0;
  cursor: pointer;
  flex: 0 0 auto;
`;

export const DropIcon = styled(Flex).attrs({})`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(${arrowDown});
  background-position: center;
/*  transform: ${({ flipped }) => `rotate(${flipped ? "180deg" : "0"})`};*/
  transform: ${({ flipped }) => `scaleY(${flipped ? "-1" : "1"})`};
`;

export const Item = styled(Flex).attrs({
  fontSize: 3,
  px: 2,
  py: 3,
  color: "copyGrey"
})`
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  &:hover {
    background-color: #eff3fa;
  }
`;

export const ItemLabel = styled(Text).attrs({
  mr: 1
})``;

export const ItemMeta = styled(Text).attrs({
  color: "greyBlue"
})``;

export const OptionsContainer = styled(Column).attrs({
  bg: "white",
  borderRadius: 2
})`
  position: absolute;
  z-index: 10;
  top: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
`;
