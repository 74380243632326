import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  * { 
    box-sizing: border-box;    
    font-family: ${props => props.theme.fonts.sansSerif};
  }
  body{
    margin: 0;
    background-color: ${props => props.theme.colors["bodyColor"]};
    line-height: 1.15;
  }
  ::placeholder{
    color: ${props => props.theme.colors["lightBlue"]};
    opacity: 0.6;
    font-weight: normal;
  }
`;
