import Amplify from 'aws-amplify';

const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION;
const AWS_COGNITO_USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;
const AWS_COGNITO_APP_CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AWS_COGNITO_REGION,
    userPoolId: AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: AWS_COGNITO_APP_CLIENT_ID,
  },
  Analytics: {
    disabled: true,
  },
});
