import React from "react";
import { Row } from "../Common";
import { getMonth } from "../../utils/utils";
import Dropdown from "../Dropdown";
import { Item, ItemMeta, ItemLabel } from "../Dropdown/styles";

const DatePickerControl = props => {
  const { year, month } = props;
  const { changeYear, changeMonth } = props;
  const { data } = props;
  if (!data) return null;
  const years = Object.keys(data);
  const months = year ? data[year].list : [];
  return (
    <Row>
      <Dropdown
        mr={3}
        value={(month === "all" ? "All" : getMonth(month)) || ""}
        options={months}
        renderItem={(cursor, collapse) => {
          const month = cursor === "all" ? "All" : getMonth(cursor);
          const meta = cursor === "all" ? data[year].total : data[year][cursor];
          return (
            <Item
              value={cursor}
              key={cursor}
              onClick={() => {
                collapse();
                changeMonth(cursor);
              }}
            >
              <ItemLabel>{month}</ItemLabel>
              <ItemMeta>({meta})</ItemMeta>
            </Item>
          );
        }}
      />
      <Dropdown
        value={year || ""}
        options={years}
        renderItem={(cursor, collapse) => {
          return (
            <Item
              value={cursor}
              key={cursor}
              onClick={() => {
                collapse();
                changeYear(cursor);
              }}
            >
              <ItemLabel>{cursor}</ItemLabel>
              <ItemMeta>({data[cursor].total})</ItemMeta>
            </Item>
          );
        }}
      />
    </Row>
  );
};

export default DatePickerControl;
