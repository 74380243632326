import React, { Component } from "react";
import {
  OptionsContainer,
  Item,
  SelectContainer,
  MainContainer,
  CurrentValue,
  DropIcon,
  ExpandButton
} from "./styles";

class Dropdown extends Component {
  static defaultProps = {
    value: "",
    options: [],
    propertyName: "value"
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  expand() {
    this.setState({ expanded: true });
  }

  collapse() {
    this.setState({ expanded: false });
  }

  handleTriggerClick() {
    this.setState(state => ({ ...state, expanded: !state.expanded }));
  }

  render() {
    const {
      value,
      options,
      propertyName,
      renderItem,
      onChange,
      ...rest
    } = this.props;
    const { expanded } = this.state;

    return (
      <SelectContainer
        tabIndex="0"
        onBlur={() => {
          this.collapse();
        }}
        {...rest}
      >
        <MainContainer
          onClick={() => this.handleTriggerClick()}
          expanded={expanded}
        >
          <CurrentValue>{value}</CurrentValue>
          <ExpandButton>
            <DropIcon icon="angle-down" flipped={expanded} />
          </ExpandButton>
        </MainContainer>
        {expanded && (
          <OptionsContainer>
            {options.map(item =>
              renderItem ? (
                renderItem(item, () => this.collapse())
              ) : (
                <Item
                  key={item[propertyName]}
                  onClick={() => this.onChange(item[propertyName])}
                >
                  {item.constructor === Object ? item.label : item}
                </Item>
              )
            )}
          </OptionsContainer>
        )}
      </SelectContainer>
    );
  }
}
export default Dropdown;
