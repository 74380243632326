import React from "react";
import styled from "styled-components";
import { Flex, Image } from "../Common";
import arrowLeft from "../../assets/icons/arrow-left.svg";

export const Page = styled(Flex).attrs(({ active }) => ({
  color: active ? "white" : "lightBlue",
  bg: active ? "lightBlue" : "transparent",
  borderRadius: 2
}))`
  width: 32px;
  height: 32px;
  user-select: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: ${({ active }) => (active ? "default" : "pointer")};
  &:hover {
    color: ${({ active, theme: { colors } }) =>
      active ? colors.darkGrey : colors.darkGrey};
    background-color: ${({ active, theme: { colors } }) =>
      active ? colors.lightBlue : colors.pageHover};
  }
`;

export const PageChanger = styled(Flex).attrs({
  as: "button",
  borderRadius: 2,
  bg: "white",
})`
  cursor: pointer;
  border: ${({ theme }) => `2px solid ${theme.colors.lightBlue}`};
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  &:disabled{
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const IconNext = styled(Image).attrs({
  src: arrowLeft
})`
  transform: scaleX(-1);
`;
export const IconPrev = styled(Image).attrs({
  src: arrowLeft
})``;

export const ChangePage = props => {
  const { prev } = props;
  return (
    <PageChanger {...props}>{prev ? <IconPrev /> : <IconNext />}</PageChanger>
  );
};
