import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "mobx-react";
import { history } from "./models/Routing";
import { store } from "./models";

import Routes from "./routes";
import theme from "./theme";

function App() {
  return (
    <Provider {...store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Routes />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
