import React from "react";
import { observer, inject } from "mobx-react";
import PrivateRoutes from "./private";
import PublicRoutes from "./public";
import { GlobalStyles } from "../global";

const Routes = ({ auth }) => (
  <>
    <GlobalStyles />
    {!!auth.token ? <PrivateRoutes /> : <PublicRoutes />}
  </>
);

export default inject("auth", "history")(observer(Routes));
