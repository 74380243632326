export default {
  fontSizes: [12, 14, 16, 20, 24, 28, 32, 48, 64],
  space: [0, 4, 8, 16, 24, 32,48, 64, 128, 256],
  radii: [0, "2px", "4px", "8px"],
  fonts: {
    serif: "athelas, georgia, times, serif",
    sansSerif: "Roboto, sans-serif"
  },
  colors: {
    purple: "#CAAEE7",
    blue: "#37A3C5",
    darkBlue: "#1F407C",
    green: "#49B96D",
    darkGreen: "#129D3F",
    inputBorder: '#8EAAE1',
    inputBorderLight: '#D9E1F0',
    lightBlue: "#ABC0E9",
    darkGrey: "#556481",
    greyBlue: "#B8C7E4",
    inactiveGrey: "#D7DEED",
    bgGrey: "#CCC",
    white: "#FFF",
    bodyColor: "#F4F7FD",
    copy: "#333",
    greyCopy: '#5E5E5E',
    pageHover: '#D8E4FD'
  }
};
