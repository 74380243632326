import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Row, Logo, Text, Image, Column } from "../Common";
import logout from "../../assets/icons/logout.svg";
import { EmptyLink } from "../Common";

export const HeaderMain = styled(Column).attrs({
  width: "100%",
  bg: "bodyColor"
})`
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const HeaderContainer = styled(Row).attrs({
  alignItems: "center",
  justifyContent: "space-between",
  px: 4,
  pt: 4,
  mb: 3,
  width: "100%",
  bg: "bodyColor"
})``;

export const HeaderLogo = styled(Logo).attrs({
  width: "140px"
})``;

export const LogoutContainer = styled(EmptyLink).attrs({
  as: NavLink,
  display: "flex",
})`
  align-items: center;
  justify-content: flex-end;
`;

export const IconLogout = styled(Image).attrs({
  src: logout,
  mr: 2
})``;

export const LogoutLabel = styled(Text).attrs({
  fontSize: 2,
  fontWeight: 700,
  cursor: "pointer",
  color: "#A1AFC9"
})``;
